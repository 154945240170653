const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/';
}

// test
// let baseUrl;
// if (prod) {
//     baseUrl = '/efm-dashboard/api/v1/';
// } else {
//     // baseUrl = '/api/v1/';
//     baseUrl = '/efm-dashboard/api/v1/';
// }

export default {
    auth : {
        login  : baseUrl + 'auth/login/',
        status : baseUrl + 'auth/status/'
    },
    testOptions : baseUrl + 'prm-tracker/master-data/payee/options/'

};
