import guards from './guards';
const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Dashboard = () => import(/* webpackChunkName: "home" */'../views/admin/dashboard/dashboard');
const ChangePassword = () => import(/* webpackChunkName: "home" */'../views/auth/UpdatePassword');
const Modules = () => import(/* webpackChunkName: "home" */'../views/admin/projects/components/pending-test-cases');

export default {
    path        : '/',
    name        : 'DashboardLayout',
    redirect    : '/dashboard/',
    component   : Layout,
    beforeEnter : guards.loggedInGuard,
    children    : [
        {
            path      : '/app/dashboard/',
            name      : 'Dashboard',
            component : Dashboard
        },
        {
            path      : '/change-password/',
            name      : 'ChangePassword',
            component : ChangePassword
        },
        {
            path      : '/app/modules/',
            name      : 'Modules',
            component : Modules
        },
        {
            path      : '/app/project/modules/fullView',
            name      : 'ModulesFullView',
            component : () => import(/* webpackChunkName: "home" */'../views/admin/projects/components/ProjectModuleFullView')
        },
        {
            path      : '/app/project/testcases/fullView',
            name      : 'TestcasesFullView',
            component : () => import(/* webpackChunkName: "home" */'../views/admin/projects/components/ProjectTestCasesFullView')
        },
        {
            path      : '/app/projects/',
            name      : 'ProjectPage',
            component : () => import(/* webpackChunkName: "home" */'../views/admin/projects/ProjectPage')

        }
    ]
};
