const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');
const Login = () => import('../views/auth/Login');

export default {
    path      : '/',
    name      : 'AuthLayout',
    redirect  : '/login/',
    component : Layout,
    children  : [
        {
            path      : '/login/',
            name      : 'Login',
            component : Login
        },
        {
            path      : '/logout/',
            name      : 'Logout',
            component : () => import('../views/auth/Logout')
        }
    ]
};
