<template>
    <!--suppress HtmlUnknownTag -->
    <div class="ld-over" :class="[{'running': dataLoading}]" v-if="hideIfNoData ? data.length > 0 : true">
        <div class="ld ld-ring ld-spin" v-if="dataLoading"></div>
        <table class="table of-h mb-0 round-2 of-h">
            <TableHeader class="bg-info text-black-50" :fields="fields"/>
            <transition-group appear :name="animation" class="vuetable-body" tag="tbody">
                <tr v-for="(item, itemIndex) in data" :key="item[keyField] || itemIndex">
                    <TableCell
                        v-for="(field, fieldIndex) in fields"

                        :key="fieldIndex"
                        :class-name="field.dataClass"
                        :row-index="itemIndex"
                        :field="field"
                        :item="item"

                        @cellClicked="onCellClicked"
                        @cellRightClicked="onCellRightClicked"
                        @cellDoubleClicked="onCellDoubleClicked">
                        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                            <slot :name="slot" v-bind="scope"/>
                        </template>
                    </TableCell>
                </tr>
            </transition-group>
        </table>
    </div>
</template>

<script>

import TableCell from '@lego/tables//TableCell';
import TableHeader from '@lego/tables//TableHeader';

export default {
    name       : 'simple-table',
    components : { TableHeader, TableCell },
    props      : {
        animation    : { type : String, default : 'fade' },
        fields       : { type : Array, required : true },
        keyField     : { type : String, default : undefined, required : false },
        data         : { type : Array, required : true },
        showBorder   : { type : Boolean, default : false },
        hideIfNoData : { type : Boolean, default : false }

    },

    data : function () {
        return {
            eventPrefix : 'simpleTable:',
            dataLoading : false
        };
    },

    beforeMount () {
    },

    methods : {
        showLoadingAnimation () {
            this.dataLoading = true;
        },

        hideLoadingAnimation () {
            this.dataLoading = false;
        },

        getTitle (field) {
            if (typeof (field.title) === 'function') return field.title();

            return typeof (field.title) === 'undefined'
                ? field.name.replace('.', ' ')
                : field.title;
        },

        getObjectValue (object, path, defaultValue) {
            defaultValue = (typeof defaultValue === 'undefined') ? null : defaultValue;

            let obj = object;
            if (path.trim() !== '') {
                const keys = path.split('.');
                keys.forEach(function (key) {
                    if (obj !== null && typeof obj[key] !== 'undefined' && obj[key] !== null) {
                        obj = obj[key];
                    } else {
                        obj = defaultValue;
                    }
                });
            }
            return obj;
        },

        /*
        * Utils
        * */
        extractArgs (string) {
            return string.split(':')[1];
        },
        extractName (string) {
            return string.split(':')[0].trim();
        },

        /*
        * Events
        * */
        onCellClicked (dataItem, field, event) {
            this.$emit(this.eventPrefix + 'cell-clicked', dataItem, field, event);
        },
        onCellDoubleClicked (dataItem, field, event) {
            this.$emit(this.eventPrefix + 'cell-dblclicked', dataItem, field, event);
        },
        onCellRightClicked (dataItem, field, event) {
            this.$emit(this.eventPrefix + 'cell-rightclicked', dataItem, field, event);
        }

    }
};
</script>
